<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card>
                <v-card-title class="elevation-1">
                  Active Bills Settlement
                  <v-spacer></v-spacer>
                  <v-flex xs12 sm2 pt-7 pl-3>
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fromDate"
                          label="From Date"
                          append-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fromDate"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm2 pt-7 pl-3>
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="toDate"
                          label="To Date"
                          append-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="toDate"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="report"
                  :search="search"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item.name`]="{ item }">
                    <span
                      >{{ item.buyer.firstname }}
                      {{ item.buyer.lastname }}</span
                    >
                  </template>
                  <template v-slot:[`item.amount`]="{ item }">
                    <span>Rs. {{ item.amount }}</span>
                  </template>
                  <template v-slot:[`item.promoCodeDiscount`]="{ item }">
                    <span>Rs. {{ item.promoCodeDiscount }}</span>
                  </template>
                  <template v-slot:[`item.billDate`]="{ item }">
                    <span>{{ item.billDate.slice(0, 10) }}</span>
                  </template>
                  <template v-slot:[`item.bill`]="{ item }">
                    <span
                      v-if="
                        item.billImage &&
                        (item.billImage.split('.').pop() == 'pdf' ||
                          item.billImage.split('.').pop() == 'docx' ||
                          item.billImage.split('.').pop() == 'mp4')
                      "
                    >
                      <a target="_blank" :href="mediaUURL + item.billImage">
                        <v-btn
                          style="
                            border-radius: 14px;
                            border: 0.5px solid #707070;
                          "
                          px-5
                          pt-0
                          text
                          small
                          outlined
                        >
                          View
                        </v-btn>
                      </a>
                    </span>
                    <span v-else>
                      <v-btn
                        @click="(doc = item.billImage), (docDialog = true)"
                        style="border-radius: 14px; border: 0.5px solid #707070"
                        px-5
                        pt-0
                        text
                        small
                        outlined
                      >
                        View
                      </v-btn>
                    </span>
                    <!-- <v-dialog max-width="900px">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          style="
                            border-radius: 14px;
                            border: 0.5px solid #707070;
                          "
                          px-5
                          pt-0
                          text
                          small
                          outlined
                          >View</v-btn
                        >
                      </template>
                      <v-card class="mx-auto my-auto">
                        <v-toolbar elevate-on-scroll fixed>
                          Bill Proof
                        </v-toolbar>
                        <v-divider></v-divider>
                        <span>
                          <iframe
                            v-if="item.billImage"
                            id="documentImg"
                            :src="mediaUURL + item.billImage"
                            height="700px"
                            width="900px"
                            contain
                          >
                          </iframe>
                        </span>
                      </v-card>
                    </v-dialog> -->
                  </template>
                </v-data-table>
                <div class="pt-2">
                  <v-pagination
                    :length="pages"
                    :total-visible="7"
                    v-model="currentPage"
                    color="#FF0000"
                  ></v-pagination>
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
        <v-dialog persistent v-model="docDialog" width="70%">
          <v-card>
            <v-toolbar dense dark>
              <v-spacer></v-spacer>
              <v-btn right icon dark @click="(doc = null), (docDialog = false)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-title class="text-h5 grey lighten-2"> Bill </v-card-title>
            <v-card-text v-if="doc">
              <v-img :src="mediaUURL + doc"></v-img>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                small
                color="red"
                text
                @click="(docDialog = false), (doc = null)"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
    <script>
import axios from "axios";
export default {
  data() {
    return {
      checkbox: true,
      ServerError: false,
      file: null,
      showsnackbar: false,
      msg: null,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 12,
      code: null,
      widthOfCard: "250px",
      addslider: false,
      appLoading: false,
      dialogDelete: false,
      search: "",
      report: [],
      fromDate: "",
      toDate: "",
      menu: false,
      menu1: false,
      docs: [],
      doc: null,
      documents: "",
      docDialog: false,
      headers: [
        { text: "Buyer Name", value: "name" },
        { text: "Phone", value: "buyer.phone" },
        { text: "Shop Name", value: "shopname" },
        // { text: "Bill Id", value: "billId" },
        { text: "Bill Amount", value: "billAmount" },
        // { text: "Bill Date", value: "billDate" },
        { text: "Bill", value: "bill" },
        { text: "Coins", value: "coins" },
        //   { text: "Actions", value: "_id", sortable: false },
      ],
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
    fromDate() {
      this.getData();
    },
    toDate() {
      this.getData();
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/offline/purchases/bills/list/admin/active",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          limit: this.limit,
          page: this.currentPage,
          fromDate: this.fromDate,
          toDate: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.report = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
    